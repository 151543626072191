export const environment = {
  production: false,
  staging: false,
  qa: false,
  dev: true,
  local: false,
  TOKEN_KEY: 'sb-nvgtnpbquqjcqnxnxbwg-auth-token',
  APP_URL: 'https://devplatform.visions.team',
  API_URL: 'https://visiondev-api.visions.team',
  SUPABASE: {
    SUPABASE_URL: 'https://nvgtnpbquqjcqnxnxbwg.supabase.co',
    SUPABASE_KEY:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im52Z3RucGJxdXFqY3FueG54YndnIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcwMTg3MzcxNSwiZXhwIjoyMDE3NDQ5NzE1fQ.rfCqm8eG4x0uCt9nKudBu559BoGRlFxeBbnxYQ9qF3k',
  },
};
