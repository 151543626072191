/* eslint-disable no-underscore-dangle */
import { PhoneNumberUtil } from 'google-libphonenumber';
export class CustomValidators {
  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const config = {
      requiredField: 'Please select an option.',
      required: 'Please enter a value.',
      invalidCharacters: 'Please Enter Valid Input',
      invalidSpace: 'Please use only letters',
      invalidNumericInput: 'Please enter numbers only.',
      invalidAlphanumericInput: 'Input contains invalid characters.',
      invalidEmail: 'Please enter a valid email.',
      invalidMobileNumber: 'Please enter a valid phone number.',
      invalidDomainName: 'Please enter a valid domain',
      invalidDate: 'Please enter valid date.',
      invalidYearName: 'Please Enter Valid Name like AY-2023',
      maxlength: `Max ${validatorValue.requiredLength} characters, keep it short`,
      minlength: `Min ${validatorValue.requiredLength} characters, keep it long`,
      invalidGreaterThanZeroInput: `Number Should Be Greater than 0`,
      invalidWebsiteLink: `Please Enter Valid Link`,
      invalidFirstLetter: `First Letter Should Be Capital`,
    };
    return config[validatorName];
  }

  // required validation for option
  public static requiredValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '' || control.value.length === 0) {
      return { requiredField: true };
    } else {
      return null;
    }
  }

  // // charector and space allowed validator
  public static alphaSpaceValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }

    if (control.value && control.value.match(/^[a-zA-Z!@#&()\-\_\:\\'",.\/?\s;%*\$+]+$/)) {
      return null;
    } else {
      return { invalidCharacters: true };
    }
  }

  public static capitalFirstLetterValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }

    const firstLetter = control.value.charAt(0);

    if (firstLetter === firstLetter.toUpperCase()) {
      return null;
    } else {
      return { invalidFirstLetter: true };
    }
  }

  // // charector and space allowed validator
  public static alphaValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (/^[a-zA-Z\s]+$/.test(control.value)) {
      return null;
    } else {
      return { invalidSpace: true };
    }
  }

  // alphanumeric value validator
  public static alphanumericValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }

    if (control.value && control.value.match(/^[a-zA-Z0-9!@#&()\-\_\:\\'",.\/?\s;%*\$+]+$/)) {
      return null;
    } else {
      return { invalidAlphanumericInput: true };
    }
  }

  public static greaterThanZeroValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (Number(control.value) > 0) {
      return null;
    } else {
      return { invalidGreaterThanZeroInput: true };
    }
  }

  // invalid Email Validator
  public static emailValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (!control.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      return { invalidEmail: true };
    } else {
      return null;
    }
  }

  // mobile number validator
  public static mobileValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (isNaN(control.value)) {
      // RFC 2822 compliant regex
      return { invalidNumericInput: true };
    } else {
      if (control.value && control.value.match(/^\d$/)) {
        return null;
      } else {
        return { invalidMobileNumber: true };
      }
    }
  }

  // Domain Validator
  public static domainValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (control.value && control.value.match(/^(?!\-)(?!.*\-\-)[A-Za-z0-9\-]{1,63}(?<!\-)\.(?!-)([A-Za-z0-9\-]{1,63}\.?)+$/)) {
      return null;
    } else {
      return { invalidDomainName: true };
    }
  }

  // Website Link Validator
  public static websiteLinkValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (control.value && control.value.match(/^https?:\/\/(www\.)?[A-Za-z0-9\-]+(\.[A-Za-z0-9\-]+)*(\.[A-Za-z]{2,})(\/.*)?$/)) {
      return null;
    } else {
      return { invalidWebsiteLink: true };
    }
  }

  // date validation
  public static dateValidator(control): any {
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }
    if (control.value.indexOf('/') > -1 || control.value.indexOf('-') > -1 || control.value.indexOf('.') > -1 || control.value.indexOf(',') > -1) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }

  static phoneNumberValidator(diallingCode, phoneNumberInput) {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneNumberUtil.parse('+' + diallingCode + phoneNumberInput);
      if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
        return { invalidMobileNumber: true };
      }
    } catch (error) {
      return { invalidMobileNumber: true };
    }
  }
}
